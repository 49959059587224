import { Component, OnInit } from '@angular/core';

declare var JitsiMeetExternalAPI: any;

@Component({
  selector: 'app-meet',
  templateUrl: './meet.component.html',
  styleUrls: ['./meet.component.css']
})
export class MeetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const domain = 'meet.jit.si';
    const options = {
        roomName: 'mootiva-testing-room',
        parentNode: document.querySelector('#meet')
    };
    const api = new JitsiMeetExternalAPI(domain, options);
    api.on('readyToClose', () => {
      alert('readyToClose callback. Aca se puede redirigir y/o hacer otras cosas');
    });
  }

}
