<div class="background">
  <div class="row justify-content-center my-5 ml-md-2">
    <div
      id="carouselExampleControls"
      class="carousel slide col-md-6 col-10 px-md-3 px-0"
      data-interval="false"
      data-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="row justify-content-center">
          <div class="col-12">
            <div
              *ngFor="
                let item of sections;
                let index = index;
                let isFirst = first
              "
              [ngClass]="{ active: isFirst }"
              class="carousel-item"
            >
              <img
                onerror="this.src = '/assets/images/logo-placeholder.png'"
                (click)="goToSection(item?.id)"
                class="d-block img-slider"
                [src]="item?.image?.large"
                alt="First slide"
              />
              <div
                class="description-img d-flex align-tems-center justify-content-center"
              >
                <p
                  (click)="goToSection(item?.id)"
                  class="text-description my-auto"
                >
                  {{ item?.title | excerpt : 55}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselExampleControls"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleControls"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
  <div class="row justify-content-center my-5">
    <button (click)="seeMore()" class="btn btn-course col-auto p-4">
      Ver más
    </button>
  </div>
</div>
