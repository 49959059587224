<div class="pricing-row row">
  <div class="col-12 col-md-10 col-lg-4 mx-auto mx-lg-0 c-md-space" *ngFor="let content of pricingContent">
    <div class="card text-center border pricing-item {{content.tag}}">
      <div class="card-body text-center">
        <div class="price-title">
          <h4 class="mb-0">{{content.title}}</h4>
          <p>{{content.sub_title}}</p>
        </div>
        <hr class="mb-3">
        <div class="price-wrap ">
          <h5><span class="price-ammount">$ {{content.price}}</span></h5>
          <span class="description"> {{content.price_type}} </span>
        </div>
        <div class="card-feature mb-5">
          <ul class="list-unstyled px-0 mb-0">
            <li *ngFor="let list of content.pricing_list">
              <p><i class="fa mr-2 {{list.icon}}"></i> {{list.value}} </p>
              <hr>
            </li>
          </ul>
        </div>
        <a href="javascript:void(0)" class="btn btn btn-primary btn-click-effect">{{content.button_name}}</a>
      </div>
    </div>
  </div>
</div>
