<div class="lessonHeader" [class.expanded]="lesson.expand" [class.approved]="lesson.approved">
    <div class="maskToggler" (click)="toggleVisibility()"></div>
    <div class="btn btnRound mr-3" [class.btn-success]="lesson.approved">U{{lesson.order}}</div>
    <p class="title" [innerHTML]="lesson.name"></p>
    <img class="lessonStatus lessonStatusDone" (click)="changeLessonStatus(false)" *ngIf="lesson.approved"
        src="assets/images/mootiva/check_green.svg" title="Undone">
    <button class="btn btnRound btnToggler" (click)="toggleVisibility()"><img src="assets/images/mootiva/plus_icon.svg"></button>
</div>
<div class="row lessonContent mt-4 mb-5"
    *ngIf="available"
    [class.fade-in-animation]="lesson.expand"
    [class.fade-out-animation]="!lesson.expand" [hidden]="!lesson.expand">
    <div class="col-12">
        <div class="row">
            <div class="col-md-7 d-flex flex-column justify-content-between mb-3">
                <div class="desc">
                    <p [innerHTML]="lesson.description"></p>
                </div>
            </div>
            <div class="col-md-5">
                <div class="thumbContainer hover-shadow" *ngIf="videoPicture" (click)="openVideoModal()" data-toggle="modal"
                    [attr.data-target]="'#modal-' + videoId">
                    <img [src]="videoPicture">
                    <div class="playContainer">
                        <img class="playButton" src="assets/images/lesson/play_button.svg">
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-6 col-md-7 d-flex align-items-center" style="font-size: 1.1rem;">
                <a href="#" *ngIf="!isLast" class="text-info" (click)="!!changeLessonStatus(true)">
                  <svg class="mr-2" width="25" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2918 0.293787C16.3847 0.200661 16.4951 0.126775 16.6166 0.0763616C16.7381 0.0259488 16.8683 0 16.9998 0C17.1314 0 17.2616 0.0259488 17.3831 0.0763616C17.5046 0.126775 17.6149 0.200661 17.7078 0.293787L23.7078 6.29379C23.801 6.38668 23.8748 6.49703 23.9253 6.61852C23.9757 6.74001 24.0016 6.87025 24.0016 7.00179C24.0016 7.13332 23.9757 7.26356 23.9253 7.38505C23.8748 7.50654 23.801 7.6169 23.7078 7.70979L17.7078 13.7098C17.5201 13.8976 17.2654 14.003 16.9998 14.003C16.7343 14.003 16.4796 13.8976 16.2918 13.7098C16.1041 13.522 15.9986 13.2673 15.9986 13.0018C15.9986 12.7362 16.1041 12.4816 16.2918 12.2938L21.5858 7.00179L16.2918 1.70979C16.1987 1.6169 16.1248 1.50654 16.0744 1.38505C16.024 1.26356 15.998 1.13332 15.998 1.00179C15.998 0.870253 16.024 0.74001 16.0744 0.61852C16.1248 0.497029 16.1987 0.386678 16.2918 0.293787Z"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.00195C0 6.73674 0.105357 6.48238 0.292893 6.29485C0.48043 6.10731 0.734784 6.00195 1 6.00195H22C22.2652 6.00195 22.5196 6.10731 22.7071 6.29485C22.8946 6.48238 23 6.73674 23 7.00195C23 7.26717 22.8946 7.52152 22.7071 7.70906C22.5196 7.8966 22.2652 8.00195 22 8.00195H1C0.734784 8.00195 0.48043 7.8966 0.292893 7.70906C0.105357 7.52152 0 7.26717 0 7.00195Z"/>
                  </svg>
                  Siguiente unidad
                </a>
                <a href="#" *ngIf="isLast" class="text-info" (click)="!!changeLessonStatus(true)">Finalizar</a>
            </div>
            <div class="col-6 col-md-5">
                <a [href]="attachmentLink" target="_blank" *ngIf="lesson.pdf" class="btn btn-link text-info download">
                    <button class="btn btnRound mr-2"><img src="assets/images/mootiva/download.svg"></button> Descargar material</a>
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" *ngIf="videoId && isLessonBoxShown == false" [id]="'modal-' + videoId" tabindex="-1"
    role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" [hidden]="isLessonBoxShown">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content" [class.darken]="videoLoaded">
            <div class="modal-header">
                <button #closeModal type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>

            </div>
            <div class="modal-body text-center">
                <img *ngIf="!videoLoaded" class="img-fluid" style="margin: auto;" src="assets/images/loading.gif">
                <div [hidden]="!videoLoaded" class="playerWrap" *ngIf="videoId" [id]="videoId"></div>
            </div>
        </div>
    </div>
</div>
