import { Pipe, PipeTransform } from '@angular/core';
/*
 * Hide the first characters of a string
 * Usage:
 *   value | mask: char: placesToHide
 * Example:
 *   {{ 0123456789012345 | mask: 12: }}
 *   formats to: **** **** **** 2345
*/
@Pipe({name: 'cardMask'})
export class CardMaskPipe implements PipeTransform {
  transform( value: string, char: string = '*', places: number = 12 ): string {
    value = value.replace(/\s+/g, '');
    const maskedSection = value.slice(0, places).replace(/[0-9]/g, char);
    const visibleSection = value.slice(places);
    return (maskedSection + visibleSection); // .replace(/([\w*-]{4})/g, '$1 ').trim();
  }
}
