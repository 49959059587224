<div class="row">
    <div class="col-md-3" *ngFor="let method of methods">
        <div class="methodCard">
            <div class="info shadow-box">
                <div class="imageTop">
                        <img [src]="'assets/images/home/' + method.image">
                </div>
                <p class="title">
                    {{ method.title }}
                </p>
                <p class="methodDescription">
                    {{method.description}}
                </p>
            </div>

        </div>

    </div>
</div>