import {BrowserModule} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {LoadingBarModule} from '@ngx-loading-bar/core';

import {AppComponent} from './app.component';
import {MainComponent} from './Main/Main.component';
import {HomeComponent} from './Pages/Home/Home.component';
import {AppRoutingModule} from './app-routing.module';
import {WidgetsModule} from './Widgets/Widgets.module';
import {MenuComponent} from './Layouts/Menu/HeaderMenu/HeaderMenu/HeaderMenu.component';
import {HeaderOneComponent} from './Layouts/Header/HeaderOne/HeaderOne.component';
import {TemplateModule} from './Template/Template.module';
import {FooterOneComponent} from './Layouts/Footer/FooterOne/FooterOne.component';
import {FooterMenuComponent} from './Layouts/Menu/FooterMenu/FooterMenu/FooterMenu.component';
import {MenuItems} from './Core/Menu/MenuItems/MenuItems';
import {DropletService} from './Services/Droplet.service';
import {StickyheaderComponent} from './Layouts/Header/StickyHeader/StickyHeader.component';
import {SessionModule} from './Pages/Session/Session.module';
import {GuardService} from './Services/Auth/guard.service';
import {ApiService} from './Services/Api.service';
import { StatesService } from './Services/states.service';
import {PublicGuardService} from './Services/Auth/publicGuard.service';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgxBarcodeModule} from 'ngx-barcode';
import {InitProvider} from './init-provider';
import { MeetComponent } from './meet/meet.component';
import { RegistrationAllowedService } from './Services/Auth/registrationAlowed.service';
import { ToastrModule } from 'ngx-toastr';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function initProviderFactory(provider: InitProvider) {
  return () => provider.load();
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HomeComponent,
    MenuComponent,
    HeaderOneComponent,
    FooterOneComponent,
    FooterMenuComponent,
    StickyheaderComponent,
    MeetComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    WidgetsModule,
    TemplateModule,
    HttpClientModule,
    SessionModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxBarcodeModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    
  ],
  providers: [
    MenuItems,
    DropletService,
    ApiService,
    StatesService,
    GuardService,
    PublicGuardService,
    RegistrationAllowedService,
    InitProvider,
    {provide: APP_INITIALIZER, useFactory: initProviderFactory, deps: [InitProvider], multi: true}
  ],
  exports: [],
  bootstrap: [AppComponent]
})

export class AppModule {
}
