// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiUrl: 'http://admin.adoratrices-dev.staging.keetup.com/api/v1/',
  baseUrl: 'http://admin.adoratrices-dev.staging.keetup.com/',
  paymentsUrl: 'http://fenixpayments.staging.keetup.com/',
  apiKeyMap: 'AIzaSyABNf-IQEYMiot3cFI_Ele5uW8ig8DW8FA',
  auth: {
    clientSecret: 'xGbAhWL960HycCoboFNvKdhpWJxnLdxIs55Gjn95',
    clientId: '2'
  },
  adminBaseUrl: 'http://lumiadmin.live.keetup.com/password/reset'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
