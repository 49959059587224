import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'courses-list',
  templateUrl: './courses-list.component.html',
  styleUrls: ['./courses-list.component.scss']
})
export class CoursesListComponent implements OnInit {

  courses = [
    {
      name: 'Bateria',
      description: 'Aprenda com o Prof. Bruno Gafanhoto, Mestre em Música pela Jamey Aebersold Jazz Program (EUA), as técnicas que ele aprendeu em mais de 10 anos de experiência como baterista e professor. De Rock e Funk até Jazz e Música Brasileira, tenha um curso montado só para você, de acordo com seus interesses e habilidades.',
      image: 'battery.png'
    },
    {
      name: 'Baixo',
      description: 'Tenha um curso online de Baixo montado só para você, de acordo com seus interesses e habilidades.',
      start: '2020',
      image: 'bass.png'

    },
    {
      name: 'Guitarra',
      description: 'Tenha um curso online de Guitarra montado só para você, de acordo com seus interesses e habilidades.',
      start: '2020',
      image: 'guitar.png'
    },
    {
      name: 'Fotografia',
      description: 'Tenha um curso online de Fotografia montado só para você, de acordo com seus interesses e habilidades.',
      start: '2020',
      image: 'photo.png'
    },
  ]
  constructor() { }

  ngOnInit() {
  }

}
