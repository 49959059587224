<header id="header-sec" class="header-v1">
   <div class="header-wrap" id ="header-wrap">
      <div class="container px-0">
          <nav class="navbar navbar-expand-lg navbar-dark theme-navbar">
              <ng-container *ngIf="currentUrl == '/home' || currentUrl == '/login' || currentUrl == '/register'; else elseBlock">
                <div class="d-none d-lg-block">
                  <app-theme-light-logo logoClass="navbar-brand"></app-theme-light-logo>
                </div>
                <div class="d-lg-none">
                  <app-theme-dark-logo logoClass="navbar-brand"></app-theme-dark-logo>
                </div>
              </ng-container>
              <ng-template #elseBlock>
                <app-theme-dark-logo logoClass="navbar-brand"></app-theme-dark-logo>
              </ng-template>
              <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#main-header"
                aria-controls="navbarColor03" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="navbar-collapse collapse" id="main-header">
                <app-header-menu  class="margin-left-auto"></app-header-menu>
                <app-lets-talk [whiteText]="currentUrl=='/login' || currentUrl=='/register'"></app-lets-talk>
              </div>
              <!-- navbar collapse header menu -->
          </nav>
      </div>
   </div>
   <!-- header wrap -->
   <app-stickyheader></app-stickyheader>
   <!-- sticky header close -->
</header>
<!-- header sec close -->
