import { Component, OnInit } from '@angular/core';
import { DropletService } from '../../Services/Droplet.service';
import { InitProvider } from '../../init-provider';

@Component({
  selector: 'app-home',
  templateUrl: './Home.component.html',
  styleUrls: ['./Home.component.scss']
})
export class HomeComponent implements OnInit {
  gridOverlay: any;
  serviceItems: any;
  testimonialData: any;
  counterContent: any;
  pricingContent: any;
  company: any;

  /**
   * Portfolio client slider config
   */
  serviceItemSlideConfig = {
    'slidesToShow': 4,
    'slidesToScroll': 1,
    'cssEase': 'linear',
    'arrows': true,
    'dots': false,
    'autoplay': false,
    'autoplaySpeed': 1000,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  /**
   * Recent Work Content Heading
   */
  recentWorkHeading: any = {
    title: 'Recent Work',
    content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s,'
  };

  /**
   * Explore Studio content
   */
  exploreStudio: any = ['assets/images/process/1.png', 'assets/images/process/2.png', 'assets/images/process/3.png'];

  /**
   * Client Says Content Heading
   */
  clientSaysHeading: any = {
    title: 'Client Says',
    content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s,'
  };

  /**
   * Content of Contact section
   */
  contactContent: any = {
    title: 'Become an ally of Reliable and Experienced Agency',
    content: 'Enter your details & we\'ll be in touch to discuss your project',
    email: 'support@theironnetwork.org',
    contactNumber: '+01 2345 678 910',
    contactTo: '+016272627273',
    image: 'https://via.placeholder.com/700x700/'
  };

  constructor( public service: DropletService, initProvider: InitProvider ) {
    this.company = initProvider.getSettings();
    console.log('este es el company', this.company);
    
  }

  ngOnInit() {
    // blog overlay content
    this.service.getBlogContent().subscribe(
      response => {
        this.gridOverlay = response;
      },
      err => console.log(err),
      () => this.gridOverlay
    );

    // service content
    this.service.getServiceContent().subscribe(
      response => {
        this.serviceItems = response;
      },
      err => console.log(err),
      () => this.serviceItems
    );

    // counter content
    this.service.getCounterContent().subscribe(
      response => {
        this.counterContent = response;
      },
      err => console.log(err),
      () => this.counterContent
    );

    // testimonial content
    this.service.getTestimonialContent().subscribe(
      response => {
        this.testimonialData = response;
      },
      err => console.log(err),
      () => this.testimonialData
    );

    // pricing content
    this.service.getPricingContent().subscribe(
      res => {
        this.pricingContent = res;
      },
      err => console.log(err),
      () => this.pricingContent
    );
  }
}
