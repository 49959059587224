import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { InitProvider } from "./init-provider";
import { AuthService } from "./Services/Auth/auth.service";
import { FaviconService } from "./Services/favicon.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  constructor(
    public router: Router,
    public translateProvider: TranslateService,
    public initProvider: InitProvider,
    public authService: AuthService,
    public favicon: FaviconService
  ) {
    const company = initProvider.getSettings();
    if (company.favicon) {
      console.log("favi", company.favicon.medium);

      favicon.set(company.favicon.medium);
    }
    document.documentElement.style.setProperty(
      "--primary-color",
      company.color_1
    );
    document.documentElement.style.setProperty(
      "--secondary-color",
      company.color_2
    );
    document.documentElement.style.setProperty("--info-color", company.color_3);
    document.documentElement.style.setProperty(
      "--extra-color",
      company.color_4
    );
    document.documentElement.style.setProperty(
      "--info-font-color",
      company.color_5
    );
    document.documentElement.style.setProperty(
      "--primary-color-darken",
      this.lightenDarkenColor(company.color_1, -20)
    );
    document.documentElement.style.setProperty(
      "--secondary-color-darken",
      this.lightenDarkenColor(company.color_2, -20)
    );
    document.documentElement.style.setProperty(
      "--info-color-darken",
      this.lightenDarkenColor(company.color_3, -20)
    );

    this.translateProvider.setDefaultLang("en");
    if (authService.user) this.translateProvider.use(authService.user.language);
    else this.translateProvider.use(company.language);
  }

  ngOnInit() {}

  lightenDarkenColor(col, amt) {
    let usePound = false;

    if (col[0] === "#") {
      col = col.slice(1);
      usePound = true;
    }

    const num = parseInt(col, 16);

    let r = (num >> 16) + amt;

    if (r > 255) {
      r = 255;
    } else if (r < 0) {
      r = 0;
    }

    let b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) {
      b = 255;
    } else if (b < 0) {
      b = 0;
    }

    let g = (num & 0x0000ff) + amt;

    if (g > 255) {
      g = 255;
    } else if (g < 0) {
      g = 0;
    }

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  }
}
