import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-carousel-home",
  templateUrl: "./carousel-home.component.html",
  styleUrls: ["./carousel-home.component.scss"],
})
export class CarouselHomeComponent implements OnInit {
  @Input() sections: any = [];

  constructor(private router: Router) {}

  ngOnInit(): void {}
  public goToSection(sectionId: any) {
    this.router.navigate(["/logged", "section", "profile", sectionId]);
  }
  public seeMore() {
    this.router.navigate(["/logged", "section"]);
  }
}
