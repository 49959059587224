<div >
    <div *ngFor="let course of courses" class="container-fluid courseListItem shadow-box">
        <div class="row">
            <div class="col-md-3">
                <img [src]="'assets/images/home/' + course.image">
            </div>
            <div class="col-md-9 padding-left-5">
                <h3 class="title"> {{ course.name }}</h3>
                <p class="description">
                    {{course.description}}
                </p>
                <div *ngIf="course.start" class="start">
                   Em {{course.start}}
                </div>
            </div>
        </div>
    </div>
</div>