import { Injectable } from '@angular/core';
import { ApiService } from '../Api.service';
import { LoginRequest } from 'src/app/Interfaces/Requests/Auth/login-request';
import { environment } from 'src/environments/environment.prod';
import { RegisterRequest } from 'src/app/Interfaces/Requests/Auth/register-request';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loginUrl = 'oauth/token';
  meUrl = 'me';
  registerUrl = 'oauth/register';

  constructor(private apiService: ApiService, public translateService: TranslateService) {
  }

  async logIn(email: string, password: string) {
    const loginRequest: LoginRequest = {
      grant_type: 'password',
      client_id: environment.auth.clientId,
      client_secret: environment.auth.clientSecret,
      username: email,
      password: password
    }

    try {
      const response: { access_token: string, refresh_token: string } = await this.apiService.post(this.loginUrl, loginRequest);
      localStorage.setItem('access_token', response.access_token);
      localStorage.setItem('refresh_token', response.refresh_token);
      await this.saveUserData();
      return true;
    } catch (error) {
      console.error(error);
      throw (error)
    }

  }

  register(firstName: string, lastName: string, email: string, password: string) {
    const date = new Date();
    const registerRequest: RegisterRequest = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      email_confirmation: email,
      password: password,
      password_confirmation: password,
      zip: 1234,
      categories: '1,2',
      timezone: 'America/Sao_Paulo',
      registration_date: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    }

    return new Promise<void>((resolve, reject) => {
      this.apiService.post(this.registerUrl, registerRequest)
        .then(() => {
          this.logIn(email, password).then(() => resolve()).catch(() => reject())

        })
        .catch((error) => {
          reject(error)
        })

    })

  }

  async saveUserData() {
    const response: { data: any } = await this.apiService.get(this.meUrl);
    localStorage.setItem('user', JSON.stringify(response.data));
    this.translateService.use(response.data.language);
    return response.data;
  }

  public get user() {
    const data = JSON.parse(localStorage.getItem('user'));
    return data;
  }


  public isAuthenticated(): boolean {
    const token = localStorage.getItem('access_token');
    return token ? true : false;
  }

  logOut() {
    localStorage.clear();
  }

  async getProfileData() {
    try {
      const response: { data: any } = await this.apiService.get(this.meUrl);
      localStorage.setItem('user', JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      return error;
    }

  }


  logOutWithRedirect() {
    localStorage.clear();
    window.location.href = '/home';
  }


}
