import { Component, EventEmitter, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/Services/Auth/auth.service';
import { CoursesService } from 'src/app/Services/Courses.service';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/Api.service';

@Component({
  selector: 'app-reviewform',
  templateUrl: './review-form.component.html',
  styleUrls: ['./review-form.component.scss']
})
export class ReviewFormComponent implements OnInit {
  @Input() extra = null;
  @Input() courseId = null;
  @Input() course = null;
  user;
  niveles = [];

  constructor(public authService: AuthService, public courseService: CoursesService, private router: Router
    , private apiService: ApiService) {
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));

    if (!this.course) {
      this.courseService.getCourseById(this.courseId).then((courseData) => {
        this.course = courseData.data;
        this.course.lessons.data = this.course.lessons.data.map((course) => {
          return { ...course, expand: false };
        });

        setTimeout(() => {
          document.getElementById('radio-1')['checked'] = true;
          document.getElementById('radio-4')['checked'] = true;
        }, 500);

      }).catch((error) => {
        console.log(error);
        this.router.navigate(['home']);
      });
    } else {
      this.course.lessons.data = this.course.lessons.data.map((course) => {
        return { ...course, expand: false };
      });

      setTimeout(() => {
        document.getElementById('radio-1')['checked'] = true;
        document.getElementById('radio-4')['checked'] = true;
      }, 500);
    }
  }


  continue() {
      $(".modal-backdrop").remove();
      this.router.navigateByUrl('/logged/home');
  }

  async onSubmit(form) {

    const reviewToStore = {
      course: this.course.id,
      liked: Number(form.liked),
      difficulty: Number(form.difficulty)
    }

    if (!reviewToStore.liked || reviewToStore.liked == 0) {
      reviewToStore.liked = 3;
    }
    if (!reviewToStore.difficulty || reviewToStore.difficulty == 0) {
      reviewToStore.difficulty = 5;
    }

    console.log(reviewToStore);
    try {
      let levelsApi = await (this.apiService.get('levels?per_page=12/'));

      if (reviewToStore.difficulty == 1) {

      }

      if (reviewToStore.difficulty == 2) {

      }

      if (reviewToStore.difficulty == 3) {

      }

      if (reviewToStore.difficulty == 4) {

      }

      if (reviewToStore.difficulty == 5) {

      }
      this.niveles = levelsApi.data;

      let nivel = this.niveles.filter(item => {
        return item.level == reviewToStore.difficulty;
      });

      const response = await this.courseService.sendCourseReview(reviewToStore);

      this.continue();
    } catch (error) {
      console.log(error);
    }
  }
}
