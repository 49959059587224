import { Injectable } from '@angular/core';
import { ApiService } from './Api.service';
import { LoginRequest } from 'src/app/Interfaces/Requests/Auth/login-request';
import { environment } from 'src/environments/environment.prod';
import { RegisterRequest } from 'src/app/Interfaces/Requests/Auth/register-request';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {

  coursesUrl = 'public/courses';
  baseDataUrl = 'data';
  lessonStatusUrl = 'lesson_status';
  coursesListUrl = 'public/list/courses';
  contactUrl = 'contact';
  subscribeUrl = 'subscription';
  reviewUrl = 'review';

  constructor(private apiService: ApiService) {
  }


  async getCourses() {
    try {
      const courses = await this.apiService.get(this.coursesUrl);
      return courses;
    } catch (error) {
      return error;
    }
  }

  async getCourseById(courseId) {
    try {
      const course = await this.apiService.get(this.coursesUrl.concat('/').concat(courseId));
      return course;
    } catch (error) {
      return error;
    }
  }

  async getVideoData(videoUri) {
    try {
      const videoData = await this.apiService.get(this.baseDataUrl.concat(videoUri));
      return videoData;
    } catch (error) {
      return error;
    }
  }

  async changeLessonStatus(status, lessonId) {
    try {
      const data = {
        lesson: lessonId,
        status
      };
      const videoData = await this.apiService.post(this.lessonStatusUrl, data);
      return videoData;
    } catch (error) {
      return error;
    }
  }

  async getPublicList(page = 1) {
    try {
      const courses = await this.apiService.get(this.coursesListUrl.concat('?page=' + page));
      return courses;
    } catch (error) {
      return error;
    }
  }

  async sendContactForm(values) {
    try {
      await this.apiService.post(this.contactUrl, values);
      return true;
    } catch (error) {
      return error;
    }
  }

  async subscribeToActiveCampaign(email) {
    try {
      const response = await this.apiService.post(this.subscribeUrl, email);
      return response;
    } catch (error) {
      return error;
    }
  }


  public getSubscriptionHeaders(): {} {
    const headers = {};
    headers['Api-Token'] = 'dac882db85b93567fed982e67d4a64c9a527fc516fda33776a3ab05dca63f8abdebd8405';
    headers['Content-'];
    return headers;
  }

  async getListWithFilters(filters = '', page = 1) {
    try {
      const courses = await this.apiService.get(this.coursesListUrl.concat('?page=' + page).concat(filters));
      return courses;
    } catch (error) {
      return error;
    }
  }



  async sendCourseReview(review) {
    try {
      const response = await this.apiService.post(this.reviewUrl, review);
      return response;
    } catch (error) {
      return error;
    }
  }


  async purchase(courseId) {
    try {
      const res = await this.apiService.post('courses/' + courseId + '/purchases', []);
      return res;
    } catch (error) {
      return error;
    }
  }
}
