import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { InitProvider } from '../../init-provider';

@Injectable()

export class RegistrationAllowedService implements CanActivate {
  constructor(
    public authService: AuthService,
    public router: Router,
    public initProvider: InitProvider
  ) {}

  canActivate(): boolean {
    if (!this.initProvider.getSettings().allow_registration) {
      this.router.navigate(['/home']);
    }
    return true;
  }
}
