import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


import { Router } from '@angular/router';


@Component({
  selector: 'product-list-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ProductItemComponent implements OnInit {
  @Input()
    product: any;
  constructor(private router: Router) { }

  ngOnInit() {
  }

  onProductPress(product) {
    this.router.navigate(['/logged/products/profile'+product.id]);
  }
  onConversationPress() {
    
  }

}
