<div class="service-item card">
   <div class="icon-set" *ngIf="!serviceItem.image">
      <div class="service-svg-1" >
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.396 126.849">
            <path class="svg-service" d="M57.148,30a20,20,0,0,1,34.641,0l29.85,51.7a20,20,0,0,1-17.321,30h-59.7A20,20,0,0,1,27.3,81.7Z" transform="translate(94.358 -30.29) rotate(68)"/>
         </svg>
      </div>
      <div class="service-svg-2" *ngIf="!serviceItem.image"> 
         <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 110.192 103.176">
            <path data-name="Polygon Copy" attr.class="{{serviceItem.polygon_color}}" d="M57.148,30a20,20,0,0,1,34.641,0l29.85,51.7a20,20,0,0,1-17.321,30h-59.7A20,20,0,0,1,27.3,81.7Z" transform="translate(-10.791 -22.848) rotate(7)"/>
         </svg>
      </div>
      <div class="icon-wrap" *ngIf="!serviceItem.image">
         <i class="{{serviceItem.icon}} text-white"></i>
      </div>
    
   </div>
   <div class="service-top-right" *ngIf="serviceItem.image">
         <img [src]="serviceItem.image">
      </div>
   <div class="sec-title">
      <h6 class="mb-3">{{serviceItem.title}}</h6>
   </div>
   <div class="sec-content">
      <p class="mb-0">{{serviceItem.content}}</p>
   </div>
   <div class="red-line"></div>
</div>	
<!-- service-item wrap close -->