import { Injectable } from '@angular/core';
import { ApiService } from './Api.service';

@Injectable({
  providedIn: 'root'
})
export class StatesService {
  private statesUrl = 'states';

  constructor(private apiService: ApiService) { }

  async getStates() {
    try {
      const states = await this.apiService.get(this.statesUrl);
      return states['data'];
    } catch (error) {
      return error;
    }
  }
}
