<ul class="navbar-nav mr-n3 mx-lg-auto px-0 {{stickyClass}}"
   [class.white-text-navbar]="currentUrl=='/login'  || currentUrl == 'register'">
   <li class="nav-item" [ngClass]="{'menu-item-has-child': menuItem.type == 'sub'}" *ngFor="let menuItem of items">
      <a class="nav-link" [class.white-text-navbar]="currentUrl=='/login'  || currentUrl == '/register'"
         [routerLink]="['/', menuItem.state]" routerLinkActive="active-link"
         *ngIf="menuItem.type == 'link'">{{ menuItem?.name | translate }}
      </a>
      <a class="nav-link" [state]="{ ignoreLoadingBar: true }"
         [class.white-text-navbar]="currentUrl=='/login'  || currentUrl == '/register'" [fragment]="menuItem.fragment"
         [routerLink]="['/', menuItem.state]"
         [class.active-link]="currentUrl == '/' + menuItem.state + '#' + menuItem.fragment"
         *ngIf="menuItem.type == 'fragment'">{{ menuItem?.name | translate}}
      </a>
      <a class="nav-link" [state]="{ ignoreLoadingBar: true }"
         [class.white-text-navbar]="currentUrl=='/login'  || currentUrl == '/register'"
         [class.active-link]="currentUrl == '/home'" [routerLink]="['/', menuItem.state]"
         *ngIf="menuItem.type == 'custom'">{{ menuItem?.name | translate }}
      </a>
      <a class="nav-link" [routerLink]="['/', menuItem.state[0], menuItem.state[1]]" routerLinkActive="active-link"
         *ngIf="menuItem.type == 'nested'" [id]="menuItem.id ? menuItem.id : ''">{{ menuItem?.name | translate }}
         <span *ngIf="menuItem.id == 'messages'" class="countBadge"> 2 </span>
      </a>
      <a class="nav-link" *ngIf="menuItem.type == 'sub'" href="javascript:void(0);">{{ menuItem?.name | translate }}
      </a>
      <ul class="sub-menu list-unstyled pr-lg-0" *ngIf="menuItem.type == 'sub' && menuItem?.children">
         <li class="nav-item" *ngFor="let grandchildItem of menuItem?.children"
            [ngClass]="{'menu-item-has-child': grandchildItem.type == 'subchild'}">
            <a class="nav-link" [routerLink]="['/'+menuItem.state+'/'+grandchildItem.state]"
               routerLinkActive="active-link" *ngIf="grandchildItem.type == 'link'">{{ grandchildItem?.name }}
            </a>
            <a class="nav-link" href="javascript:void(0);"
               *ngIf="grandchildItem.type == 'subchild'">{{ grandchildItem?.name | translate }}
            </a>
            <ul class="sub-menu list-unstyled">
               <li class="nav-item" *ngFor="let subChild of grandchildItem.children">
                  <a class="nav-link" *ngIf="subChild.type == 'link'" routerLinkActive="active-link"
                     [routerLink]="['/'+grandchildItem.state+'/'+subChild.state]">
                     {{subChild.name | translate}}
                  </a>
               </li>
            </ul>
         </li>
      </ul>
   </li>
</ul>