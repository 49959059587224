import { excerptPipe } from './excerpt.pipe';
import { NgModule } from '@angular/core';
import { CardMaskPipe } from './char-mask.pipe';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    CardMaskPipe,
    excerptPipe
  ],
  imports: [CommonModule],
  exports: [
    CardMaskPipe,
    excerptPipe
  ]
})
export class PipesModule { }
