import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './PageNotFound.component.html',
  styleUrls: ['./PageNotFound.component.scss']
})
export class PageNotFoundComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
