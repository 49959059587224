import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from 'src/app/Services/Auth/auth.service';
import {environment} from 'src/environments/environment.prod';
import {InitProvider} from '../../../init-provider';

import {ApiService} from '../../../Services/Api.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public form: FormGroup;
  emailPattern = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$';
  showLoginError = false;
  showEmailSuccess = false;
  loading = false;
  passwordRecoveryUrl = environment.baseUrl + '/password/reset';
  company: any;
  view_plans_logout = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private initProvider: InitProvider,
    private activatedRoute: ActivatedRoute,
    private api: ApiService,
  ) {
    this.company = initProvider.getSettings();
    this.passwordRecoveryUrl = this.company.base_url + '/password/reset';
  }

  ngOnInit() {
    this.view_plans_logout = this.company.view_plans_logout;

    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
    
    });
  }
  sendLink(){
    console.log('link de envio');
    if (this.form.valid) {
      this.loading = true;
      this.api.post('forgot/password', {
        email: this.form.get('email').value,
      })
      .then(response => {
        console.log(response);
        this.loading = false;
        if (response.status){
          this.showEmailSuccess=true;
          this.showLoginError=false;

        }
        else{
          this.showLoginError=true;
          this.showEmailSuccess=false;

        }
        
      })
      .catch(error => {
        this.showLoginError=true;
        this.showEmailSuccess=false;
        this.loading = false;
      });

    }
    else{
      this.loading = false;
    

    }
  }


}
