import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { CoursesService } from "src/app/Services/Courses.service";
import { DomSanitizer } from "@angular/platform-browser";
declare var $;
import { AuthService } from "src/app/Services/Auth/auth.service";

import { InitProvider } from "../../init-provider";
import { ViewportScroller } from "@angular/common";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-faqs",
  templateUrl: "./faqs.component.html",
  styleUrls: ["./faqs.component.scss"],
})
export class FaqsComponent implements OnInit, AfterViewInit {
  @ViewChild("procedure") elementoRef: ElementRef;
  submitted = false;
  sendingStatus = {
    loading: false,
    message: null,
    error: false,
    submitted: false,
  };

  videoId = "383643119";
  videoLoaded = false;
  isVideoShown = false;
  videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
    "https://player.vimeo.com/video/" + this.videoId
  );

  faqs = [];

  currentUser = this.authService.user;
  public rulesProcedure: string = "";
  public rule: string = "";
  constructor(
    private coursesService: CoursesService,
    public authService: AuthService,
    public sanitizer: DomSanitizer,
    public initProvider: InitProvider,
    private viewportScroller: ViewportScroller,
    private activeRoute: ActivatedRoute
  ) {
    const company = this.initProvider.getSettings();
    this.rulesProcedure = company?.rules_of_procedure || "";
    const json = JSON.parse(company.faq_json);
    if (json.faqs) {
      this.faqs = json.faqs.map((faq) => {
        let actual = faq;
        actual.collapsed = false;
        return actual;
      });
    }
  }
  ngAfterViewInit(): void {
    const queryParams = this.activeRoute.snapshot.queryParams;
    this.rule = queryParams.rule;
    debugger
    if (this.rule) {
      this.goToElement();
    }
  }

  ngOnInit() {}

  setAllClosed(title) {
    this.faqs = this.faqs.map((faq) => {
      return { ...faq, collapsed: faq.title == title };
    });
  }

  onSubmit(contactForm: NgForm) {
    this.sendingStatus.submitted = true;
    this.sendingStatus.message = null;
    console.log("contact formm", this.currentUser, contactForm.value);
    if (contactForm.invalid) {
      return;
    }
    this.sendingStatus.loading = true;

    contactForm.value.name =
      this.currentUser.first_name + " " + this.currentUser.last_name;
    contactForm.value.email = this.currentUser.email;

    this.coursesService
      .sendContactForm(contactForm.value)
      .then(() => {
        this.sendingStatus.loading = false;
        this.sendingStatus.message = "El mensaje fue enviado";
        this.sendingStatus.error = false;
        this.sendingStatus.submitted = false;

        contactForm.resetForm();
      })
      .catch((error) => {
        this.sendingStatus.loading = false;
        this.sendingStatus.error = true;
        this.sendingStatus.message =
          "Error enviando el mensaje. Por favor intente otra vez";
        this.sendingStatus.submitted = false;
      });
  }

  showError(form: NgForm, fieldName) {
    if (!this.sendingStatus.submitted) {
      return false;
    }
    return form.control.get(fieldName) && !form.control.get(fieldName).valid;
  }

  openVideoModal(videoId) {
    this.videoId = videoId;
    setTimeout(() => {
      this.isVideoShown = false;
      this.videoLoaded = true;
      this.initializeVideoPlayer(videoId);
    }, 200);
  }

  initializeVideoPlayer(videoId) {
    this.videoId = videoId;
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      "https://player.vimeo.com/video/" + this.videoId
    );
    $("#modal-faq").on("hide.bs.modal", () => {
      this.videoId = null;
      this.videoUrl = null;
      try {
        document.getElementById("videoFrame").remove();
      } catch (error) {}
    });
  }

  showVideo() {
    return this.videoId && this.videoUrl;
  }

  public goToElement() {
    const element = document.getElementById("procedure");
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 1000);
    }
  }
}
