<div class="content">
   <div class="site-404-wrap sec-404-spacer text-center shape-wrap">
      <div class="shape-group shape-group-hide">
         <span class="custom-shape pos-size-1">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 597.014 600.045">
               <path data-name="Custom Polygon 1" class="svg-primary" d="M254.933,105c26.943-46.667,94.3-46.667,121.244,0L528.211,368.332c26.943,46.667-6.736,105-60.622,105H163.52c-53.886,0-87.565-58.333-60.622-105Z" transform="translate(289.247 -116.26) rotate(48)"></path>
            </svg>
         </span>
         <span class="custom-shape pos-size-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.413 60.176">
               <path data-name="Custom Polygon 2" class="svg-border svg-border-1" d="M25.109,16.5a11,11,0,0,1,19.053,0L55.1,35.452a11,11,0,0,1-9.526,16.5H23.693a11,11,0,0,1-9.526-16.5Z" transform="translate(45.416 -13.816) rotate(68)"></path>
            </svg>
         </span>
         <span class="custom-shape pos-size-3">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118.742 111.528">
               <path data-name="Custom Polygon 3" class="svg-border svg-border-2" d="M57.148,30a20,20,0,0,1,34.641,0l29.85,51.7a20,20,0,0,1-17.321,30h-59.7A20,20,0,0,1,27.3,81.7Z" transform="translate(123.95 131.338) rotate(-172)"></path>
             </svg>
         </span>
         <span class="custom-shape pos-size-4">                     
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 482.073 504.275">
               <path xmlns="http://www.w3.org/2000/svg" data-name="Custom Polygon 4" class="svg-gray" d="M229.2,75c19.245-33.333,67.358-33.333,86.6,0L465.195,333.753c19.245,33.333-4.811,75-43.3,75H123.111c-38.49,0-62.546-41.667-43.3-75Z" transform="translate(351.63 -86.448) rotate(68)"></path>
            </svg>
         </span>
      </div>
      <!-- shape group close -->
      <div class="content-404-wrap not-found-bg px-3">
         <div class="sec-content">
            <div class="not-found-desc mb-5 d-inline-block">
               <img class="alignnone size-medium" src="https://via.placeholder.com/300x206.png" alt="" width="300" height="206">
            </div>
            <div class="home-button">
               <a class="btn btn-primary btn-square" routerLink="/home">Go To Homepage</a>
            </div>
         </div>
      </div>
   </div>
   <!-- site-404-wrap -->
</div>