<div [class.selected]="plan.selected" *ngFor="let plan of plans" class="row border-top planContainer">
    <div class="col-md-4 col-lg-5 d-flex flex-column justify-content-center p-4 pl-5 position-relative">
        <div class="discountBox mt-4">{{ plan.discount_description }}</div>
        <!-- <div *ngIf="plan.discount" class="discountBox "> <i class="fa fa-star"></i> SUPER DESCONTO!-->
        <!-- </div>-->
        <div *ngIf="plan.discount_description!=''" class="planTitle">{{ plan.name }}</div>
    </div>

    <div class="col-md-4 col-lg-3">
        <p class="description p-4" [innerHTML]="plan.description"></p>
    </div>

    <div class="offset-lg-2 col-md-4 col-lg-2 ammount d-flex flex-column justify-content-around align-items-center">
        <i class="fa fa-star mt-4"></i>

        <div class="price" [class.hasDescription]="plan.top_price_description">
            <span *ngIf="plan.top_price_description" class="topPrice" [innerHTML]="plan.top_price_description"></span>
            <p class="total" [innerHTML]="plan.price_description"></p>
        </div>

        <div *ngIf="showTrial === 'show' && ((plan.selected) || plan.price > 0)" class="plan-button mb-4 pointer" [class.pointer]="!plan.selected" (click)="selectPlan(plan)">
            <span *ngIf="plan.selected && !plan.selecting">Seleccionado</span>
            <span *ngIf="!plan.selected && !plan.selecting">Seleccionar</span>
            <span *ngIf="plan.selecting">Selecionando <i class="fas fa-circle-notch fa-spin"></i></span>
        </div>
    </div>
</div>

<div class="row border-top"></div>
