import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { CoursesService } from 'src/app/Services/Courses.service';
import * as Player from '@vimeo/player/dist/player.js';
import { environment } from 'src/environments/environment.prod';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

declare var $;

@Component({
  selector: 'app-lessonbox',
  templateUrl: './lesson-box.component.html',
  styleUrls: ['./lesson-box.component.scss'],
})
export class LessonBoxComponent implements OnInit {
  @ViewChild('closeModal') closeModal: ElementRef<
    HTMLInputElement
  >;

  @Input() lesson;
  @Input() openBox = false;
  @Input() isLast = false;
  @Input() available = false;
  @Input() unavailableMsg = 'Para acceder al contenido de las lecciones debe comprar el curso';

  videoPicture;
  videoFrame;
  videoId;
  videoLoaded = false;
  isLessonBoxShown = false;

  @Output() boxExpanded = new EventEmitter<string>();
  @Output() allDone = new EventEmitter<boolean>();
  @Output() done = new EventEmitter<number>();

  constructor(
    private courseService: CoursesService,
    private router: Router,
    private toaster: ToastrService
  ) {}

  ngOnInit() {
    this.getThumb();
    if (this.openBox) {
      this.lesson.expand = true;
    }
  }

  getThumb() {
    if (this.lesson.video) {
      this.courseService
        .getVideoData(this.lesson.video)
        .then((data) => {
          console.log('data of!', data);
          this.videoPicture = data.body.pictures.sizes[3].link;
          this.videoId = data.body.uri.replace('/videos/', '');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  toggleVisibility() {
    if (!this.available) {
      this.toaster.error(this.unavailableMsg);
      return;
    }
    if (!this.lesson.expand) {
      this.boxExpanded.emit(this.lesson.id);
    } else {
      this.lesson.expand = false;
    }
  }

  initializeVideoPlayer() {
    const player = new Player(this.videoId, {
      id: this.videoId,
      responsive: true,
    });

    player.on('ended', () => {
      this.changeLessonStatus(true);
    });

    player.on('loaded', () => {
      this.videoLoaded = true;
    });

    $('#modal-' + this.videoId).on('hide.bs.modal', () => {
      player.unload();
      this.videoLoaded = false;
    });
  }

  openVideoModal() {
    this.videoLoaded = true;

    setTimeout(() => {
      this.initializeVideoPlayer();
    }, 200);
  }

  changeLessonStatus(status) {
    if (status) {
      this.done.emit(this.lesson.order);
    }
    this.courseService
      .changeLessonStatus(status, this.lesson.id)
      .then((data) => {
        this.lesson.approved = status;
        this.lesson.expand = false;
        this.isLessonBoxShown = false;

        if (this.closeModal) {
          this.closeModal.nativeElement.click();
        }
        console.log(data);
        if (data.status === 'finished') {
          if (data.extra !== null) {
            this.allDone.emit(true);
            // this.router.navigateByUrl( "/courses/review/" + this.lesson.course + "?extra=" + data.extra.id );
          } else {
            this.allDone.emit(false);
            // this.router.navigateByUrl("/courses/review/" + this.lesson.course);
          }
        } else if (data.extra !== null) {
          this.router.navigateByUrl('/courses/extra/' + data.extra.id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  get attachmentLink() {
    return environment.baseUrl + this.lesson.pdf;
  }
}
