import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-circular-progress-bar',
  templateUrl: './circular-progress-bar.component.html',
  styleUrls: ['./circular-progress-bar.component.scss']
})
export class CircularProgressBarComponent implements OnInit {
  @Input() progress;

  constructor() {  }

  ngOnInit(): void {
    console.log('----------', this.progress);
  }
}
