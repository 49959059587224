<div class="content py-0">
   <div class="comingsoon-wrap bg-white">
      <div class="row no-gutters comingsoon-row align-items-stretch">
         <div class="col-12 col-sm-12 col-md-12 col-lg-6 align-items-center justify-content-center d-flex order-2 order-lg-1">
            <div class="text-center px-3 px-sm-5 py-lg-0 py-5 w-100">
               <div class="text-center mrgn-b-2">
                  <h2><span class="font-3x text-uppercase">Coming Soon</span></h2>
               </div>
               <div class="row">
                  <div class="col-12 col-sm-12 col-md-10 col-lg-10 mx-auto">
                     <div class="text-center mb-5">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        </p>
                     </div>
                  </div>
               </div>
               <div class="counter-wrapper text-center mb-5 px-5">
                  <div class="row align-items-center">
                     <div class="mb-2 col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="text-center">
                           <h4 class="mb-2 font-weight-normal"><span class="font-lgx">{{days}}</span></h4>
                           <p>Days</p>
                        </div>
                     </div>
                     <div class="mb-2 col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="text-center">
                           <h4 class="mb-2 font-weight-normal"><span class="font-lgx">{{hours}}</span></h4>
                           <p>Hours</p>
                        </div>
                     </div>
                     <div class="mb-2 col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="text-center">
                           <h4 class="mb-2 font-weight-normal"><span class="font-lgx">{{minutes}}</span></h4>
                           <p>Minutes</p>
                        </div>
                     </div>
                     <div class="mb-2 col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="text-center">
                           <h4 class="mb-2 font-weight-normal"><span class="font-lgx">{{seconds}}</span></h4>
                           <p>Seconds</p>
                        </div>
                     </div>
                  </div>
               </div>
               <!-- counter-wrapper close -->
               <div class="mrgn-b-2 text-center">
                  <span class="font-mdx">Subscribe For Your Early Access Invite.</span>
               </div>
               <div class="text-center d-sm-flex align-items-stretch justify-content-center mb-4">
                  <div class="mb-4 mb-sm-0 mr-sm-3">
                     <input class="form-control" type="email" placeholder="Enter Email">
                  </div>
                  <div>
                     <button class="btn btn-dark btn-square h-100 text-nowrap">Subscribe</button>
                  </div>
               </div>
               <a class="text-primary" routerLink="/home">Back To Home</a>
            </div>
         </div>
         <div class="col-12 col-sm-12 col-md-12 col-lg-6 d-lg-block order-1">
            <div class="comingsoon-image h-100">
               <div class="overlay-icon d-flex align-items-center justify-content-center">
                  <a class="play-icon font-xlx text-center rounded-circle bg-primary text-white comingsoonvideo-popup" href="https://www.youtube.com/watch?v=rbTVvpHF4cU"><i class="fas fa-play"></i></a>
               </div>
            </div>
            <!-- comingsoon image close -->
         </div>
      </div>
   </div>
   <!-- comingsoon wrap close -->
</div>