<header class="cover">
  <div class="cover--imageBackground">
    <img [src]="company?.home_image?.original" />
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-lg-6 p-4 position-relative mt-5">
        <div class="transparent-bg d-none d-md-block"></div>
        <div class="row">
          <div class="col-12">
            <h3 class="cover--title text-primary mb-0">
              {{ company?.home_text_title }}
            </h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 col-lg-12">
            <p class="cover--description text-secondary mt-1">
              {{ company?.home_text_description }}
            </p>
          </div>
        </div>
        <div class="row" *ngIf="company?.allow_registration">
          <div class="col">
            <a
              [routerLink]="['/register']"
              [state]="{ ignoreLoadingBar: true }"
              class="btn btn-info btn-flex btn-click-effect mt-3"
              >Crear cuenta</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="dots">
      <img class="" src="assets/images/home/dots.png" />
    </div> -->
  </div>
</header>
<div class="container">
  <div class="row section-home justify-content-center">
    <div class="col-md-6 col-12 mb-3 mb-md-0">
      <div class="row">
        <div class="col-6 img-group">
          <div class="d-block">
            <img src="assets/images/home/img1.jpg" />
          </div>
          <div class="d-block">
            <img class="img-2" src="assets/images/home/img2.jpg" />
          </div>
        </div>
        <div class="col-6">
          <div class="d-block">
            <img class="img-3" src="assets/images/home/img3.jpg" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-11 align-items-end d-flex">
      <div class="row">
        <div
          class="card-home px-2 half-a-border-on-top half-a-border-on-bottom"
        >
          <div class="text-center mb-4">
            <h3 class="title">Acerca de nosotros</h3>
          </div>
          <p class="description">
            {{ company?.phrase || '' }}
          </p>
        </div>
        <div class="elipse">
          <img class="" src="assets/images/home/elipse.jpg" />
        </div>
      </div>
    </div>
  </div>
</div>
