import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CoursesService } from 'src/app/Services/Courses.service';

@Component({
  selector: 'app-subscribe',
  templateUrl: './Subscribe.component.html',
  styleUrls: ['./Subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {

  formStatus = {
    showErrors: false,
    sending: false,
    submitted: false,
    message: null,
    okey: undefined
  }
  constructor(private coursesService: CoursesService) { }

  ngOnInit() {
  }

  onSubmit(activeCampaignForm: NgForm) {
    this.formStatus.submitted = true;
    console.log(activeCampaignForm)
    if (activeCampaignForm.invalid) {
      return this.formStatus.showErrors = true;
    }
    this.coursesService.subscribeToActiveCampaign(activeCampaignForm.value).then((data) => {
      if (data.error == 'duplicate') {
        this.formStatus.showErrors = true;
        this.formStatus.message = 'Email already subscribed';
        this.formStatus.okey = false;
      } else {
        this.formStatus.showErrors = false;
        this.formStatus.message = 'Subscribed succesfully';
        this.formStatus.submitted = false;
        this.formStatus.okey = true;
        activeCampaignForm.resetForm();
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  showError(form: NgForm, fieldName) {
    if (!this.formStatus.submitted) {
      return false;
    }
    if(form.control.get(fieldName) && !form.control.get(fieldName).valid) {
      this.formStatus.message = null;
      return true;
    }
    return false;
  }


  notEmpty(form: NgForm, fieldName) {
    if (!this.formStatus.submitted) {
      return false;
    }
    return form.control.get(fieldName).value.length > 0;
  }




}
