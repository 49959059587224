import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';

import 'rxjs/add/operator/map';
import { environment } from '../environments/environment.prod';

@Injectable()
export class InitProvider {

  private settings: {
    'id': string,
    'api_url': string,
    'base_url': string,
    'logo': any,
    'shadow_logo': any,
    'home_image': any,
    'sign_up_image': any,
    'sign_in_image': any,
    'home_text_title': string,
    'home_text_description': string,
    'login_text_title': string,
    'login_text_description': string,
    'register_text_title': string,
    'register_text_description': string,
    'color_1': string,
    'color_2': string,
    'color_3': string,
    'color_4': string,
    'color_5': string,
    'faq_json': string,
    'term_and_conditions': string,
    'allow_registration': boolean,
    'language' : string,
    'favicon': any,
    'rules_of_procedure' : string
  };

  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document
  ) { }

  public getSettings() {
    return this.settings;
  }

  load() {
    const domain = window.location.href.split('/')[2];

    return new Promise((resolve, reject) => {
      let apiUrl = environment.apiUrl;
      if (document.location.protocol === 'https:') {
        apiUrl = environment.apiUrl.replace('http://', 'https://');
      } else if (document.location.protocol === 'http:') {
        apiUrl = environment.apiUrl.replace('https://', 'http://');
      }

      this.http
        .get( apiUrl + 'public/settings?domain=' + domain )
        .subscribe( response => {
          this.settings = response['data'];
          console.log( this.settings );
          resolve( true );
        });
    });
  }
}
