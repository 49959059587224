<div class="sec-title mb-5">
   <h2>Contact Us</h2>
</div>
<div class="contact-info-blocks row mb-3">
   <div class="contact-block col-12 col-sm-6 mb-4">
      <div class="contact-tilte">
         <h5>Address</h5>
      </div>
      <div class="contact-content">
         <p>E-51, Industrial area, Phase2 Mohali, Punjab</p>
      </div>
   </div>
   <!-- contact-block wrap close -->
   <div class="contact-block col-12 col-sm-6 mb-4">
      <div class="contact-tilte">
         <h5>Contact</h5>
      </div>
      <div class="contact-content mb-3">
         <p class="d-inline-block mb-0">Call: <a href="tel:+01 123 456 7890" class="mb-1 d-inline-block">+01 123 456 7890</a></p>
         <p class="d-inline-block mb-0">Mail: <a href="mailto:info@example.com" class="mb-0 d-inline-block">info@example.com</a></p>
      </div>
      <!-- contact-content wrap close -->
   </div>
   <!-- contact-block wrap close -->
</div>
<!-- contact-info-blocks wrap close -->
