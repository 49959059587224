<div class="row conversationItemRow" (click)="onConversationPress()">
    <div class="col-md-2">
        <div class="imageContainer">
            <img [src]="conversation.image">
        </div>
    </div>
    <div class="col-md-7">
        <p class="m-0">
            <span class="course"> Aula: {{conversation.course}} </span>
            <span class="instructor"> Profesor: {{conversation.instructor}}</span>
        </p>
    </div>
    <div class="col-md-3 flex-end last">
        <p class="m-0" *ngIf="!conversation.newMessages">
            {{ conversation.lastMessage }} <span class="fas fa-angle-right"></span> </p>
        <p class="m-0 featuredMessage" id="feats" *ngIf="conversation.newMessages">
            {{ conversation.lastMessage }} <span class="fas fa-angle-right"></span>
            <span class="featuredMsgs"> 2 </span>
        </p>

    </div>
</div>