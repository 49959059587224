<div class="chatBox">
    <div class="closeRow" (click)="closeClicked.emit(true)">
        <div class="closeIcon">
            X
        </div>
    </div>
    <div class="teacherInfo">
        <div class="profileImage">
            <img [src]="conversation?.image || defaultImage">
        </div>
        <div class="userName">
            {{ conversation ? this.conversation.instructor : 'Professor' }}
        </div>
    </div>
    <div class="chatBody">
        <div class="chatMessage" *ngFor="let message of conversation?.messages">
            <div *ngIf="message.from == 'me'" class="messageFromCurrentUser">
                {{ message.text }}
            </div>
            <div *ngIf="message.from != 'me'" class="messageFromOtherUser">
                {{ message.text }}
            </div>
        </div>
    </div>
    <div class="success" *ngIf="showSuccess">
        <p class="lead"> Thanks for your message </p>
        <p> We will communicate shortly</p>
    </div>

    <div class="bottomElement">
        <div class="chatInput">
            <form class="subscribe-form">
                <div class="form-group mb-sm-0">
                    <input name="message" type="text" placeholder="Write message here" class="form-control search-input"
                        [(ngModel)]="messageToSend">
                </div>


                <div class="subscribe-btn">
                    <button type="submit" (click)="addMessage()" class="btn btn-outline-primary"> <i
                            class="fas fa-paper-plane"></i> </button>
                </div>
            </form>
        </div>
    </div>

</div>