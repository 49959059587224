<form class="search-widget-form">
   <div class="form-group">
      <input name="name-input" class="form-control search-input" type="search" value="" placeholder="Keyword">
   </div>
   <!-- form group wrap close -->
   <div class="subscribe-btn search-btn">
      <input type="submit" value="Search" class="btn btn-dark">
   </div>
   <!-- subscribe-btn wrap close -->
</form>
<!-- search-widget-form wrap close -->