<div class="content py-0">
   <div class="maintenance-wrap shape-wrap section-spacer d-flex align-items-center">
      <div class="shape-group">
         <span class="custom-shape pos-size-1">                     
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 482.073 504.275">
               <path xmlns="http://www.w3.org/2000/svg" data-name="Custom Polygon 4" class="svg-gray" d="M229.2,75c19.245-33.333,67.358-33.333,86.6,0L465.195,333.753c19.245,33.333-4.811,75-43.3,75H123.111c-38.49,0-62.546-41.667-43.3-75Z" transform="translate(351.63 -86.448) rotate(68)"/>
            </svg>
         </span>
         <span class="custom-shape pos-size-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118.742 111.528">
               <path data-name="Custom Polygon 3" class="svg-border svg-border-3" d="M57.148,30a20,20,0,0,1,34.641,0l29.85,51.7a20,20,0,0,1-17.321,30h-59.7A20,20,0,0,1,27.3,81.7Z" transform="translate(123.95 131.338) rotate(-172)"/>
               </svg>
         </span>
         <span class="custom-shape pos-size-3">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.413 60.176">
               <path data-name="Custom Polygon 2" class="svg-border svg-border-2" d="M25.109,16.5a11,11,0,0,1,19.053,0L55.1,35.452a11,11,0,0,1-9.526,16.5H23.693a11,11,0,0,1-9.526-16.5Z" transform="translate(45.416 -13.816) rotate(68)"/>
            </svg>
         </span>
         <span class="custom-shape pos-size-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.413 60.176">
               <path data-name="Custom Polygon 2" class="svg-border svg-border-1" d="M25.109,16.5a11,11,0,0,1,19.053,0L55.1,35.452a11,11,0,0,1-9.526,16.5H23.693a11,11,0,0,1-9.526-16.5Z" transform="translate(45.416 -13.816) rotate(68)"/>
            </svg>
         </span>
      </div>
      <!-- shape group close -->
      <div class="container">
         <div class="row no-gutters">
            <div class="col-12 col-sm-11 col-md-9 col-lg-9 mx-auto d-flex align-items-center">
               <div class="maintenance-content-wrap shadow-box card w-100">                        
                  <div class="maintenance-content text-center">
                     <div class="sec-title mrgn-b-2">
                        <h3 class="font-weight-medium"><span class="font-mdx">We'll be back soon</span></h3>
                     </div>
                     <div class="image-wrap mrgn-b-2 d-inline-block">
                        <img class="img-fluid" src="https://via.placeholder.com/669x792.png" width="300" height="37">
                     </div>
                     <div class="sec-content text-center mrgn-b-2 mb-xl-5">
                        <div class="row">
                           <div class="col-12 col-sm-12 col-md-11 col-lg-11 mx-auto">
                              <p class="mb-0">We're updating our servers to provide you with a better experience.Check back momentarily to view this page...or try hitting refresh, we may already be back!
                              </p>
                           </div>
                        </div>
                     </div>
                     <form [formGroup]="form">
                        <div class="text-center d-sm-flex d-block align-items-center justify-content-center">
                           <div class="mr-sm-3 mb-3 mb-sm-0">
                              <input class="form-control" type="email" placeholder="Your Email"  formControlName ='email'>
                           </div>
                           <div>
                              <button class="btn btn-dark btn-square text-nowrap" [disabled]="form.invalid">Get Notified</button>
                           </div>
                        </div>  
                        <small class="chankya-block text-danger" *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched">
                           You must include an email address.
                        </small>
                        <small class="chankya-block text-danger" *ngIf="form.controls['email'].errors && !(form.controls['email'].hasError('required')) && form.controls['email'].touched">
                           You must include a valid email address.
                        </small>               
                     </form>
                  </div>
               </div> 
               <!-- maintenance-content wrap close   -->
            </div>
         </div>
      </div>
   </div>
   <!-- Maintenance Wrap Close -->
 </div>