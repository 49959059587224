import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { IConversation } from 'src/app/Interfaces/IConversation';

@Component({
  selector: 'product-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss']
})
export class ProductBoxComponent implements OnInit {

  defaultImage = 'https://image.shutterstock.com/image-vector/empty-photo-male-profile-gray-260nw-538707310.jpg';
  messageToSend = '';
  showSuccess = false;
  @Output() closeClicked = new EventEmitter();
  @Input() conversation: IConversation;
  constructor() { }

  ngOnInit() {



  }

  addMessage() {
    if (!this.messageToSend || this.messageToSend.trim() == '') {
      return;
    }
    if (!this.conversation.messages) {
      this.conversation.messages = []
    }
    const now = new Date();
    this.conversation.messages.push({
      text: this.messageToSend,
      from: 'me',
      date: now.getDay() + '/' + now.getMonth() + ' - ' + now.getHours() + ':' + now.getMinutes()
    });



    if (this.conversation.instructor == 'Bruna') {
      setTimeout(() => {
        this.conversation.messages.push({
          text: 'Your message was sent. We will communicate shortly.',
          from: 'other',
          date: now.getDay() + '/' + now.getMonth() + ' - ' + now.getHours() + ':' + now.getMinutes()
        });
        setTimeout(() => {
          const chatBody = document.querySelector('.chatBody');
          chatBody.scrollTo(0, chatBody.scrollHeight);
        }, 100);
        setTimeout(() => {
          this.closeClicked.emit(true);
        }, 3000);
      }, 1000);
    } else {

      this.showSuccess = true;
      setTimeout(() => {
        const chatBody = document.querySelector('.chatBody');
        chatBody.scrollTo(0, chatBody.scrollHeight);
      }, 100);

      setTimeout(() => {
        this.closeClicked.emit(true);
        this.showSuccess = false;
      }, 2500);

    }

    setTimeout(() => {
      const chatBody = document.querySelector('.chatBody');
      chatBody.scrollTo(0, chatBody.scrollHeight);
    }, 100);
    this.messageToSend = null;
  }

}
