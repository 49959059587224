<header class="cover pb-5">
  <div class="cover--imageBackground">
    <img [src]="company.sign_up_image.original" />
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-5 mb-3 mt-5">
        <div class="row">
          <div class="col-12 p-4 position-relative">
            <div class="transparent-bg d-none d-md-block"></div>
            <div class="row">
              <div class="col-12 col-md-10">
                <h3 class="cover--title text-primary">
                  {{ company.register_text_title }}
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p class="cover--description text-secondary">
                  {{ company.register_text_description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 offset-lg-3 mt-5">
        <div class="session-content text-center card">
          <div class="mb-2">
            <h5 class="text-capitalize font-weight-bold mb-4">
              {{ "Register" | translate }}
            </h5>
          </div>
          <div>
            <div class="row">
              <p
                *ngIf="company?.registration_text"
                [innerHTML]="company.registration_text"
                class="description-register"
              ></p>
              <p
                *ngIf="!company?.registration_text"
                class="description-register"
              >
                Si aún no dispones de una cuenta, por favor comuníquese al email
                .......@..........................com, enviando: nombre y
                apellido completo, DNI y datos de sus hijo/s. A la brevedad
                responderemos su solicitud.
                <br />
                <br />
                ¡Muchas gracias!
              </p>
            </div>

            <div class="row">
              <div class="col-12">
                <p class="mb-0">
                  <span class="text-muted">{{
                    "Already a member?" | translate
                  }}</span>
                  <a class="font-weight-bold ml-2" [routerLink]="['/login']">
                    {{ "SignIn" | translate }}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
