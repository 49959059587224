import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'excerpt'
})
export class excerptPipe implements PipeTransform {
  transform(input: String,limit: number): unknown {
    if (input&&input.length>limit){
      input=input.slice(0,limit);
      input+='...';
    }
    return input;
  }

}

