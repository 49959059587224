<div class="header-widget-area" style="display: flex" *ngIf="userName" [class.white]="whiteText">
  <div class="lets-talk-link">
     <a href="javascript:void(0)" (click)="logOut()" class="btn btn-info"> {{ 'LOGOUT' | translate }} </a>
  </div>
</div>
<div class="header-widget-area" style="display: flex" *ngIf="!userName">
  <div class="lets-talk-link">
     <a href="javascript:void(0)" [routerLink]="['/login']" class="btn btn-info"> {{ 'LOGIN' | translate }}  </a>
  </div>
</div>
<!-- header-widget-area wrap close -->
