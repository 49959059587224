<div class="contact-sec search-contact text-center">  
   <div class="row">
      <div class="col-12 col-lg-12">
         <div class="contact-form-wrap card">
            <form class="" action="/.xyz" method="post">
               <div class="form-group mrgn-b-2">
                  <input name="name-input" class="form-control" type="text" value="" placeholder="Enter Your Search page">
               </div>
               <div class="form-group mb-0">
                  <input class="btn btn-dark btn-click-effect d-inline-block" type="submit" value="{{btnContent}}">
               </div>
            </form>
         </div>
         <!-- contact-form-wrap close -->
      </div>   
   </div>
</div>
<!-- contact-sec and search-contact wrap close  -->