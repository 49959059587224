<div class="row">
   <div class="col-12 col-md-11 col-xl-10 mx-auto">
      <div class="row align-items-center">
         <div class="col-12 col-xl-8 mb-4 mb-xl-0">
            <img src="assets/images/process/process.png">
         </div>
         <div class="col-12 col-sm-12 col-md-12 col-xl-4 px-3 px-xl-5 mx-auto">
            <div class="explore-studio-sec">
               <div class="sec-title">
                  <h3 class="grid-item-space d-block title">Our Process</h3>   
               </div>
               <div class="sec-content mrgn-b-2">
                  <p class="font-weight-normal ">We are a digital studio agency which beleives in giving high <a class="highlight">User Experience</a>, we do this by applying systematic and consistent approach. </p>
               </div>
            </div>
            <!-- explore-studio-sec wrap close -->
            <div class="explore-std-btn">
               <a  [routerLink]="['/about-us/process']" class="btn btn-primary btn-click-effect">See all Work</a>
            </div>

         
            <!-- explore-std-btn wrap close -->
         </div>
      </div>
   </div>
   <div class="red-shape">
         <img src="assets/images/red-shape.svg">
      </div>
</div>   