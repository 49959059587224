import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";

import { MainComponent } from "./Main/Main.component";
import { HomeComponent } from "./Pages/Home/Home.component";
import { ComingsoonComponent } from "./Pages/Session/ComingSoon/ComingSoon.component";
import { MaintenanceComponent } from "./Pages/Session/Maintenance/Maintenance.component";
import { LoginComponent } from "./Pages/Session/LogIn/LogIn.component";
import { Signup2Component } from "./Pages/Session/SignUp2/SignUp2.component";
import { GuardService as AuthGuard } from "./Services/Auth/guard.service";
import { PublicGuardService as PublicGuard } from "./Services/Auth/publicGuard.service";

import { MeetComponent } from "./meet/meet.component";
import { RegistrationAllowedService as RegistrationAllowedGuard } from "./Services/Auth/registrationAlowed.service";
import { ForgotPasswordComponent } from "./Pages/Session/forgot-password/forgot-password.component";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "meet",
    component: MeetComponent,
  },
  {
    path: "",
    component: MainComponent,
    children: [
      {
        path: "home",
        component: HomeComponent,
        canActivate: [PublicGuard],
      },
      {
        path: "session/comingsoon",
        component: ComingsoonComponent,
        canActivate: [PublicGuard],
      },
      {
        path: "session/maintenance",
        component: MaintenanceComponent,
        canActivate: [PublicGuard],
      },
      {
        path: "login",
        component: LoginComponent,
        canActivate: [PublicGuard],
      },
      {
        path: "forgot",
        component: ForgotPasswordComponent,
      //   canActivate: [PublicGuard],
      },
      {
        path: "register",
        component: Signup2Component,
        // canActivate: [RegistrationAllowedGuard]
      },
      {
        path: "logged",
        loadChildren: () =>
          import("./Pages/HomeLogged/home-logged.module").then(
            (m) => m.HomeLoggedModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "courses",
        loadChildren: () =>
          import("./Pages/Courses/Courses.module").then((m) => m.CoursesModule),
        canActivate: [AuthGuard],
      },
      {
        path: "products",
        loadChildren: () =>
          import("./Pages/products/products.module").then(
            (m) => m.ProductsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "**",
        redirectTo: "home",
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(AppRoutes, { anchorScrolling: "enabled" }),
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
