<div class="row productItemRow" (click)="onProductPress(product)" style="margin-bottom: 10px;">
    <div class="containerProductImage col-md-4 flex-start">
        <div class="imageContainer" *ngIf="product && product.hero_image">
            <img class="" [src]="product.hero_image.small" style=" margin-left: 10px; border-radius: 10px;min-height: 80px;max-width: 120px;background-color: #dedede; max-width: 120px;min-width: 120px;">
        </div>
    </div>
    <div class="containerProductTitle flex-start last">

            <span class="course" style="font-size: 20px; font-weight: 600;color: #222; margin-top: 10px;">
            {{product.name}}
            </span><br />
            <span>{{product.currency.symbol}}
       {{product.price}}</span>

    </div> 
</div>