import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-faq-item',
  templateUrl: './faq-item.component.html',
  styleUrls: ['./faq-item.component.scss']
})
export class FaqItemComponent implements OnInit {

  @Input() title: string;
  @Input() content: string;

  @Input() collapsed = false;
  @Output() boxExpanded = new EventEmitter<string>();
  constructor() { }

  ngOnInit() {
  }

  toggleCollapse() {
    if (!this.collapsed) {
      this.boxExpanded.emit(this.title);
    } else {
      this.collapsed = false
    }
  }



}
