<div class="row">
   <div class="col-12 col-md-12 col-lg-3 col-xl-4 c-md-space-lg">
      <div class="service-info">
         <div class="sec-title mrgn-b-2">
            <h3 class="title">Services</h3>
         </div>
         <div class="sec-content mrgn-b-2 text-justify">
            <p>Making broadcast content more engaging. <br> The Genie allows the real-time intergration, deployment and analysis, of social media and competition content into TV broadcasts and live events.</p>
           
         </div>
      </div>
      <!-- service info wrap -->
      <div class="service-btn">
         <a [routerLink]="['/pages/services']" class="btn btn-primary btn-click-effect">Know More</a>
      </div>
      <!-- service btn -->
   </div>
   <div class="col-12 col-md-12 col-lg-9 col-xl-8">
      <div class="services-grid-wrap text-center">
         <div class="row row-eq-height">
            <ng-container *ngFor ="let item of serviceItems; let i = index">
               <div class="col-12 col-sm-4 grid-item-space" *ngIf="i<4">
                  <app-service-item [serviceItem]="item"></app-service-item>
               </div>
            </ng-container>
         </div>
      </div>
      <!-- services-grid-wrap close -->
   </div>
</div>
