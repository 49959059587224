import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/Services/Auth/auth.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-lets-talk',
  templateUrl: './LetsTalk.component.html',
  styleUrls: ['./LetsTalk.component.scss']
})
export class LetsTalkComponent implements OnInit {

  userName: string;
  currentUrl;
  @Input() whiteText: string = 'false'
  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.currentUrl = this.router.url;

    this.userName = this.authService.user ? this.authService.user.first_name : false;
    if(!this.userName && this.authService.user) {
     // this.authService.logOutWithRedirect()
    }
    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd) {
        this.currentUrl = event.url
      }
    })
  }

  logOut() {
    this.authService.logOut();
    window.location.href = "/home";
  }

}
