import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

/*
 * Menu interface
 */
export interface Menu {
  state: any;
  name?: string;
  type?: string;
  icon?: string;
  children?: ChildrenItems[];
  id?: string;
}

/*
 * Children menu interface
 */
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

@Injectable()
export class MenuItems {
  HEADERMENUITEMS = [
    {
      state: "home",
      name: "Home",
      type: "custom",
      fragment: "home",
    },
    /*{
      state: "home",
      name: this.translate.instant("Method"),
      type: "fragment",
      fragment: "metodologia"
    },
    {
      state: "home",
      name: this.translate.instant("Courses"),
      type: "fragment",
      fragment: "cursos"
    },
    {
      state: "home",
      name: this.translate.instant("Pricing"),
      type: "fragment",
      fragment: "planos"
    },
    {
      state: "home",
      name: this.translate.instant("Faqs"),
      type: "fragment",
      fragment: "faqs"
    }*/
  ];

  AUTHITEMS = [
    {
      state: ["logged", "home"],
      name: "Home",
      type: "nested",
    },
    {
      state: ["logged", "section"],
      name: "Institucional",
      type: "nested",
    },
    {
      state: ["logged", "communication"],
      name: "Comunicaciones",
      type: "nested",
    },
    {
      state: ["logged", "students"],
      name: "Estudiantes",
      type: "nested",
    },
    {
      state: ["logged", "workshop"],
      name: "Talleres",
      type: "nested",
    },

    // {
    //   state: ["courses", "aula"],
    //   name: "Rooms",
    //   type: "nested"
    // },
    /*{
      state: ["logged", "products"],
      name: this.translate.instant("Products"),
      type: "nested",
      id: 'products'
    },*/
    /*{
      state: ["logged", "messages"],
      name: this.translate.instant("Messages"),
      type: "nested",
      id: 'messages'
    },*/
    {
      state: ["logged", "faqs"],
      name: "Support",
      type: "nested",
    },
    {
      state: ["logged", "profile"],
      name: "Profile",
      type: "nested",
    },
  ];

  constructor(public translate: TranslateService) {}

  /*
   * Get all header menu
   */
  getMainMenu(): Menu[] {
    return this.HEADERMENUITEMS;
    //  return HEADERMENUITEMS;
  }

  getAuthMenu(): Menu[] {
    return this.AUTHITEMS;
  }
}
