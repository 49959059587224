<div class="container ">

    <div class="row justify-content-center">

        <div class="col-md-8 aboutHeroText">
            <div class="top">
                <p> O que é </p>
                <img src="assets/images/home/lumi_text.png">
            </div>
            <div class="info">
                A tecnologia revolucionou a forma como nos relacionamos com a televisão, com a forma que pedimos comida, com a forma que nos locomovemos pela cidade. Porquê então não revolucionar a educação?

                Nossa missão é te guiar em um caminho que se encaixe perfeitamente nas suas necessidades: Nos conte sobre você e nossa plataforma te apresenta automaticamente o conteúdo que você precisa. Tire dúvidas, receba feedback e traga a sala de aula a qualquer momento do dia para a palma da sua mão. Tenha uma aprendizagem mais precisa e mais agradável. Chegue mais rápido ao seu objetivo. Se o mundo moderno é cada vez mais ágil e objetivo, sua educação também deve ser.<br>
                <br>
                Aprenda de maneira inteligente. <br>
                <strong> Seja revolucionário. Seja moderno. <br>
                    Seja Lumi.</strong> 
                    <img class="arrowDown" src="assets/images/arrow_down.svg">
                    <button 
                    [routerLink]="['/register']" 
                    class="btn btn-outline-primary"> Experimente </button>
                    
            </div>
        </div>
    </div>
</div>