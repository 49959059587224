<div class="subscribe-wrap pt-pb-6rem bg-gray-lead">
   <div class="container">
      <div class="row">
		  <div class="col-md-3 flex-center-lead">

			<p class="lead">
					Quer saber das 
			</p>
			<p class="subLead">
					novidades em primera mão?
			</p>
		  </div>
         <div class="col-md-9 mx-auto">
   			<form class="subscribe-form"  #activeCampaignForm="ngForm"  (ngSubmit)="onSubmit(activeCampaignForm)">
   			   <div class="form-group mb-sm-0">
					 <input name="email"  type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" 
					 required class="form-control search-input" ngModel >
				  </div>
				  <span class="danger" *ngIf="showError(activeCampaignForm, 'email')"> You need to enter a mail </span>
				  <span class="danger" 
				  *ngIf="formStatus.message && !formStatus.okey 
				  && notEmpty(activeCampaignForm, 'email') 
				  && !showError(activeCampaignForm, 'email')" 
				  [innerHTML]="formStatus.message"> </span>
				  <span class="okey" *ngIf="formStatus.message && formStatus.okey" [innerHTML]="formStatus.message"> </span>
   			   <div class="subscribe-btn">
   			      <button type="submit"  class="btn btn-outline-primary"> INSCREVA-SE </button>
   			   </div>
   			</form>
            <!-- subscribe-form  -->
		   </div>
      </div>
   </div>
</div>
<!-- subscribe-wrap close -->