import {Component, OnInit, Input} from '@angular/core';
import {ApiService} from '../../Services/Api.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-pricing-list',
  templateUrl: './pricing-list.component.html',
  styleUrls: ['./pricing-list.component.scss']
})
export class PricingListComponent implements OnInit {

  @Input() showTrial: any;
  plans = [];
  user: any = {};


  constructor(private apiService: ApiService, private router: Router) {
    // console.log('this is profile!', this.plans, this.showTrial, this);


  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    console.log('ng on init! pricing', this.showTrial);
    this.get();
  }

  get() {
    this.apiService.get('plans?active=true').then((res) => {
      this.transform(res.data);
      this.plans = res.data;
      console.log( this.plans);
    });
  }

  transform(plans) {

    plans.forEach((plan) => {
      if (this.user !== null && this.user.plan !== null && plan.id === this.user.plan.id) {
        plan.selected = true;
        plan.selecting = false;
      }
    });

  }

  selectPlan(plan) {
    if (plan.selected || plan.price === 0) {
        return;
    }
    this.router.navigate(['/logged/profile/payment'], {queryParams: {plan: plan.id}});

  }
}
