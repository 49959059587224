import { Component, OnInit, Input } from '@angular/core';
import {InitProvider} from '../../../init-provider';

@Component({
  selector: 'app-theme-light-logo',
  templateUrl: './ThemeLightLogo.component.html',
  styleUrls: ['./ThemeLightLogo.component.scss']
})
export class ThemeLightLogoComponent implements OnInit {

  @Input() logoClass: any;

  public path;

  constructor(initProvider: InitProvider) {
    this.path = initProvider.getSettings().logo.medium;
  }

  ngOnInit() {}
}
