import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { MenuItems } from '../../../../Core/Menu/MenuItems/MenuItems';
import { AuthService } from 'src/app/Services/Auth/auth.service';

@Component({
   selector: 'app-header-menu',
   templateUrl: './HeaderMenu.component.html',
   styleUrls: ['./HeaderMenu.component.scss']
})

export class MenuComponent implements OnInit {

   @Input() stickyClass: any;
   currentUrl: any;
   items: any;

   constructor(public menuItems: MenuItems,
      private router: Router,
      private authService: AuthService) { }

   ngOnInit() {
      this.currentUrl = this.router.url;
      if (this.authService.isAuthenticated()) {
         this.items = this.menuItems.getAuthMenu();
      } else {
         this.items = this.menuItems.getMainMenu();
      }
      //header responsive
      if ((this.currentUrl != '/session/comingsoon') && (this.currentUrl != '/session/maintenance')
      ) {
         var menuItems = document.getElementsByClassName('menu-item-has-child')
         for (var i = 0; i < menuItems.length; i++) {
            (function (index) {
               menuItems[index].addEventListener("click", function (event) {
                  event.stopPropagation();
                  if (menuItems[index] != undefined) {
                     menuItems[index].classList.toggle('opened-submenu');
                  }
               })
            })(i);
         }
         this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
               this.currentUrl = this.router.url;
               if (this.currentUrl == '/home') {
                  window.scrollTo({ top: 0, behavior: 'smooth' })
               }
            }
         })
      }



   }
}
