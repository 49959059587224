<form [formGroup]="form">
   <div class="row">
      <div class="col-12 col-sm-6 form-group mrgn-b-2 mb-lg-5">
         <input type="text" class="form-control" placeholder="First name" formControlName ='fname'>
         <small class="text-danger" *ngIf="form.controls['fname'].hasError('required') && form.controls['fname'].touched">
            You must include a first name.
         </small>
      </div>
      <!-- form group close -->
      <div class="col-12 col-sm-6 form-group mrgn-b-2 mb-lg-5">
         <input type="text" class="form-control" placeholder="Last name" formControlName ='lname'>
         <small class="text-danger" *ngIf="form.controls['lname'].hasError('required') && form.controls['lname'].touched">
            You must include a last name.
         </small>
      </div>
      <!-- form group close -->
      <div class="col-12 col-sm-6 form-group mrgn-b-2 mb-lg-5">
         <input type="text" class="form-control" placeholder="Email address" formControlName ='email'>
         <small class="text-danger" *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched">
            You must include an email address.
         </small>
         <small class="text-danger" *ngIf="form.controls['email'].errors && !(form.controls['email'].hasError('required')) && form.controls['email'].touched">
            You must include a valid email address.
         </small>
      </div>
      <!-- form group close -->
      <div class="col-12 col-sm-6 form-group mrgn-b-2 mb-lg-5">
         <input type="text" class="form-control" placeholder="Phone" formControlName ='phone'>
         <small class="text-danger" *ngIf="form.controls['phone'].hasError('required') && form.controls['phone'].touched">
            You must include a phone.
         </small>
      </div>
      <!-- form group close -->
      <div class="col-12 form-group mrgn-b-2 mb-lg-5">
         <input type="text" class="form-control" placeholder="Message" formControlName ='message'>
         <small class="text-danger" *ngIf="form.controls['message'].hasError('required') && form.controls['message'].touched">
            You must include a message.
         </small>
      </div>
      <!-- form group close -->
      <div class="col-12">
         <button class="btn btn-primary" type="submit">Send</button>
      </div>
   </div>
</form>
<!-- form close -->