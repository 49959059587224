import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IConversation } from 'src/app/Interfaces/IConversation';



@Component({
  selector: 'chat-list-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ChatItemComponent implements OnInit {
  @Input() conversation: IConversation;
  @Output() conversationClick = new EventEmitter<IConversation>();

  constructor() { }

  ngOnInit() {
  }

  onConversationPress() {
    this.conversationClick.emit(this.conversation);
    console.log(this.conversation)
    if (this.conversation && this.conversation.newMessages && this.conversation.newMessages > 0) {
      const countBadge = document.querySelectorAll('.countBadge');
      countBadge.forEach((item) => {
        item.parentElement.removeChild(item);

      })
      document.getElementById('feats').classList.remove('featuredMessage');
      const featuredMessagesElement = document.querySelector('.featuredMsgs');
      featuredMessagesElement.parentElement.removeChild(featuredMessagesElement);

    }
  }

}
