import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'methodology',
  templateUrl: './methodology.component.html',
  styleUrls: ['./methodology.component.scss']
})
export class MethodologyComponent implements OnInit {

  methods = [
    {
      title: '100% Personalizado e Exclusivo',
      description: 'Nossa tecnologia te direciona para as aulas que você precisa.',
      image: 'interactive.png',
    },
    {
      title: 'Tecnologia + contato humano',
      description: 'Tire dúvidas e tenha exercícios corrigidos durante todo o seu plano.',
      image: 'contact.png',
    },
    {
      title: 'Estude a hora \n que quiser',
      description: 'Conteúdo disponível 24h por dia. Pratique de acordo com sua disponibilidade!',
      image: 'calendar.png',
    },
    {
      title: 'Certificados \n e bônus',
      description: 'Receba certificados e bônus personalizados a cada conquista atingida.',
      image: 'certificate.png',
    },
  ]
  constructor() { }

  ngOnInit() {
  }

}
