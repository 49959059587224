<div class="container">
  <div class="row text-center justify-content-center mb-5">
    <h4 class="title">{{ "Preguntas Frecuentes" }}</h4>
  </div>
  <div class="row justify-content-center fadeIn">
    <div class="col-md-6 col-10 px-0">
      <div
        class="mt-5 text-md-left accordion fadeIn"
        id="myAccordion"
        *ngFor="let item of faqs; let i = index"
      >
        <div class="accordion-item">
          <h2 class="accordion-header" [id]="i">
            <button
              type="button"
              class="accordion-button collapsed subTitle-support"
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#collapse' + i"
            >
              {{ item?.title }}
            </button>
          </h2>
          <div
            [attr.id]="'collapse' + i"
            class="accordion-collapse collapse"
            data-bs-parent="#myAccordion"
          >
            <div class="card-body">
              <div class="col-12 col-md content-support px-0">
                {{ item?.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row justify-content-center mt-5 mb-5 fadeIn">
  <div class="col-md-6 col-lg-4 row justify-content-center">
    <form
      class="contactForm py-5 col-11"
      #contactForm="ngForm"
      (ngSubmit)="onSubmit(contactForm)"
    >
      <div class="row">
        <p class="name-form">Hola {{ currentUser.first_name }}</p>
        <p class="faq">¡Dejanos tu consulta!</p>
      </div>
      <div class="form-group">
        <textarea
          class="form-control contactInput"
          name="question"
          rows="5"
          placeholder="Escríbe aqui tu mensaje"
          required
          ngModel
        ></textarea>
        <span class="danger" *ngIf="showError(contactForm, 'question')">
          Este campo es obligatorio
        </span>
      </div>
      <div class="row justify-content-center mt-4">
        <button style="width: 315px" class="btn btn-course col-auto p-4">
          Enviar
        </button>
      </div>
      <div class="status">
        <span
          class="danger"
          *ngIf="sendingStatus?.message && sendingStatus?.error"
          [innerHTML]="sendingStatus.message"
        >
        </span>
        <span
          class="success"
          *ngIf="sendingStatus?.message && !sendingStatus?.error"
          [innerHTML]="sendingStatus?.message"
        >
        </span>
      </div>
    </form>
  </div>
</div>

<div class="row" id="procedure">
  <div
    *ngIf="rulesProcedure != ''"
    class="row text-center justify-content-center my-5"
  >
    <h4 class="title mb-5">
      {{ "Normativa de convivencia y reglamento interno" }}
    </h4>
    <div  class="col-8">
      <div class="" [innerHTML]="rulesProcedure"></div>
    </div>
  </div>
</div>
