import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl, AbstractControl} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/Services/Auth/auth.service';
import {InitProvider} from '../../../init-provider';

@Component({
  selector: 'app-signup2',
  templateUrl: './SignUp2.component.html',
  styleUrls: ['./SignUp2.component.scss']
})
export class Signup2Component implements OnInit {
  public form: FormGroup;
  emailPattern = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$';
  loading = false;
  showRegisterError = false;
  registerErrorMessage = 'Hubo un error en el registro';
  public company: any;

  constructor(private fb: FormBuilder, private router: Router, private authService: AuthService, initProvider: InitProvider) {
    this.company = initProvider.getSettings();
  }

  ngOnInit() {
    this.form = this.fb.group(
      {
        firstName: ['', Validators.required],
        email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
        newPassword: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
        tac: ['', [Validators.requiredTrue]]
      },
      {
        validator: this.MatchPassword // your validation method
      }
    );
  }

  /*
   * Match Password Custom validation
   */
  MatchPassword(AC: AbstractControl) {
    const password = AC.get('newPassword').value; // to get value in input tag
    const confirmPassword = AC.get('confirmPassword').value; // to get value in input tag
    if (password != confirmPassword) {
      AC.get('confirmPassword').setErrors({MatchPassword: true});
    } else {
      return null;
    }
  }

  async signUp() {
    if (this.form.valid) {
      this.loading = true;
      const nameArray = this.form.get('firstName').value.split(' ');
      if (nameArray.length < 2) {
        this.loading = false;
        return this.form.controls['firstName'].setErrors({fullNameRequired: true});
      } else {
        let firstNames = '';
        let lastName = '';
        nameArray.map((name, i) => {
          if (i != nameArray.length - 1) {
            firstNames = firstNames.concat(name + ' ')
          } else {
            lastName = name;
          }
        });
        try {
          await this.authService.register(firstNames, lastName, this.form.get('email').value, this.form.get('newPassword').value);
          this.loading = false;
          return window.location.href = '/home';
        } catch (error) {
          this.showRegisterError = true;

          if (error.error.errors['email']) {
            this.registerErrorMessage = error.error.errors['email'][0];
          }

        }

      }


    } else {
      this.loading = false;
      for (const i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
    }
  }
}
